/* eslint-disable react/display-name */
import React from 'react'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { Options } from '@contentful/rich-text-react-renderer'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Typography, Divider } from '@mui/material'

const options: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
  },
  renderText: (text) => {
    return text
      .split('\n')
      .reduce(
        (children: React.ReactNode[], textSegment: string, index: number) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        },
        []
      )
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography gutterBottom>{children}</Typography>
    ),

    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,

    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => children,

    [BLOCKS.HR]: () => <Divider />,

    [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,

    [BLOCKS.HEADING_1]: (node, children) => (
      <Typography variant="h1">{children}</Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography variant="h2">{children}</Typography>
    ),

    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography variant="h3">{children}</Typography>
    ),

    [BLOCKS.HEADING_4]: (node, children) => (
      <Typography variant="h4">{children}</Typography>
    ),

    [BLOCKS.HEADING_5]: (node, children) => (
      <Typography variant="h5">{children}</Typography>
    ),

    [BLOCKS.EMBEDDED_ENTRY]: (node) => <div>{node.content}</div>,

    [INLINES.HYPERLINK]: (node, children) => {
      return <a href={node.data.uri}>{children}</a>
    },

    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      return <a>{children}</a>
    },
  },
}

export type RichtextCopyProps = Partial<GT.Contentful.IContentfulModuleText>

export default function RichtextCopy({
  copy,
}: RichtextCopyProps): React.ReactElement {
  if (!copy) return <div>Copy missing</div>
  return (
    <Typography component="div">{renderRichText(copy, options)}</Typography>
  )
}
