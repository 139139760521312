import React from 'react'
import { graphql, navigate } from 'gatsby'
import { Container, IconButton } from '@mui/material'
import { ArrowBackRounded } from '@mui/icons-material'
import Metatags from '@objects/metatags'
import RichtextCopy from '@components/richtextCopy'

export type ImprintPageProps = {
  data: {
    contentfulGenericPage: GT.GenericPage
  }
}

export default function ImprintPage({
  data,
}: ImprintPageProps): React.ReactElement {
  const PageData = data.contentfulGenericPage
  const TextModule = PageData.modules?.filter(
    (module) => module.__typename === 'ContentfulModuleText'
  )

  return (
    <React.Fragment>
      <Metatags
        meta={{
          metaTitle: PageData.metaTitle,
          metaDescription: PageData.metaDescription,
          metaOgtitle: PageData.metaOgtitle,
          metaOgdescription: PageData.metaOgdescription,
          metaOgimage: PageData.metaOgimage?.file?.url,
        }}
      />
      <Container>
        <IconButton
          onClick={() => navigate('/')}
          sx={{
            marginBottom: 4,
            marginLeft: { sx: 0, md: -4 },
          }}
          size="large"
        >
          <ArrowBackRounded />
        </IconButton>
        {TextModule?.map((mod: GT.Contentful.IContentfulModuleText) => {
          return mod.copy ? <RichtextCopy key={mod.id} copy={mod.copy} /> : null
        })}
      </Container>
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query ImprintPageQuery {
    contentfulGenericPage(titleInternal: { eq: "Impressum" }) {
      metaTitle
      metaDescription
      metaOgtitle
      metaOgdescription
      metaOgimage {
        file {
          url
        }
      }
      modules {
        ...ContentfulPageModuleFragment
      }
    }
  }
`
